import { Button, CircularProgress } from "@mui/material";

const LoadingButton = (props) => {
  return (
    <Button
      {...props}
      disabled={props.disabled ? props.disabled : props.isLoading}
    >
      {props.isLoading ? (<CircularProgress size={18} color="#ffffff" />) : props.children}
    </Button>
  );
};

export default LoadingButton;
