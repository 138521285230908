import { Typography, Stack, Paper, Divider } from "@mui/material";
import crowdlogo from "../../../assets/crowdlogo.png";
import jiralogo from "../../../assets/jiralogo.png";
import gitlablogo from "../../../assets/gitlablogo.png";
import confluencelogo from "../../../assets/confluencelogo.png";

const WelcomeScreen = (props) => {
  return (
    <Stack sx={{justifyContent: "center", alignItems: 'center', alignSelf:"center",}}>
      <Stack spacing={10} sx={{ width: "60%"}}>
        <Typography variant="h4" sx={{fontSize: '2.75rem'}}>Welcome to the Tools Portal!</Typography>
        <Typography variant="h5" sx={{ wordSpacing: "1.5px", fontSize: '16px' }}>
          The Tools Portal is a dedicated platform designed to streamline your
          requests for accessing and managing essential tools and services.
        </Typography>
        <Divider orientation="horizontal" thickness={10} flexItem />
        <Typography sx={{ wordSpacing: "1.5px", fontSize: '14px' }}>
          Currently in active development, our mission is to provide you with a
          seamless experience for submitting and tracking requests, ensuring
          quick access to the resources you need to enhance productivity.
          Through the Tools Portal, you can request: Crowd accounts, Crowd
          password resets, Jira project creation, GitLab account creation and
          more!
        </Typography>
        <Typography sx={{ wordSpacing: "1.5px", fontSize: '14px' }}>
          If you have suggestions for new tools or features, or if you'd like to
          enhance existing services, we encourage you to submit a request to our
          backlog. Your feedback and requests are invaluable as we continue to
          improve the portal to meet your needs!
        </Typography>

        <Stack direction={"row"} spacing={7} alignItems={"center"}>
          <img src={crowdlogo} style={{ height: 43 }} alt="crowd logo" />
          <img src={jiralogo} style={{ height: 35 }} alt="jira logo" />
          <img src={gitlablogo} style={{ height: 29 }} alt="jira logo" />
          <>
            <img src={confluencelogo} style={{ height: 35 }} alt="jira logo" />{" "}
            <Typography>and more...</Typography>
          </>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WelcomeScreen;
