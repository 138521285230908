import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getGroupList, getUserGroups, getUserList, useDirectoryList } from "../../../../../api/selfServe";
import React, { useEffect, useState } from "react";
import { debounce } from "../../../../../utils/helper";
import { DEFAULT_GROUPS } from "../../../../../config/constants/selfServe";


const AddAttributes = (props) => {
  	const directoryList = useDirectoryList();
  	const [groupList, setGroupList] = useState(null);
	const [userList, setUserList] = useState(null);
	const [selectedDirectory, setSelectedDirectory] = useState(props.userAttributes.selectedDirectory);
	const [clonedUsers, setClonedUsers] = useState(props.userAttributes.clonedUsers);
	const [addedGroups, setAddedGroups] = useState(props.userAttributes.selectedGroups.addedGroups);
	const [clonedGroups, setClonedGroups] = useState(props.userAttributes.selectedGroups.clonedGroups);
	const [selectedGroups, setSelectedGroups] = useState({
		addedGroups: [],
		clonedGroups: []
	});
	
  	const [isGroupListLoading, setisGroupListLoading] = useState(false);
	const [isUserListLoading, setIsUserListLoading] = useState(false);
	
	useEffect(() => {
		setSelectedGroups({
			addedGroups: addedGroups,
			clonedGroups: [...new Map(clonedGroups.map(group => [group.label, group])).values()]
		})
	}, [addedGroups, clonedGroups])

	useEffect(() => {
		const attributes = {
			selectedDirectory: selectedDirectory,
			selectedGroups: selectedGroups,
			clonedUsers: clonedUsers
		}
		props.getAttributes?.(attributes);

	},[selectedDirectory, selectedGroups])

	useEffect(() => {
		if(!selectedDirectory?.id)
			if(directoryList?.data?.length){
				setSelectedDirectory(directoryList.data[0])
				setAddedGroups(DEFAULT_GROUPS.map((item, idx) => ({id: item, label: item, checked: true})))
				setGroupList(DEFAULT_GROUPS.map((item, idx) => ({id: item, label: item, checked: true})))
			}
				
	},[directoryList.data])


  const setAttributes = async (data) => {
    if (data.field === "directory") {
      let directory = data.value;

      if (directory?.label !== selectedDirectory?.label) {
		setSelectedDirectory(directory?.label ? directory : "");
		setAddedGroups([])
        setGroupList(null)
		setClonedUsers([])
		setClonedGroups([])
		setUserList([])
      }
    } else if (data.field === "addedGroups") {
			let groups = data.value.map(item => {
						item.checked = true; 
						item.type = "addedGroup"
						return item
					});
			setAddedGroups(groups)
		}
  };

  const searchGroups = debounce(async(groupName) => {
			setisGroupListLoading(true)
			let groups = await getGroupList(selectedDirectory.id, groupName)
			setGroupList(groups);
			setisGroupListLoading(false)
	}, 500)
	
  const searchUsers = debounce(async(directoryId, username) => {
		setIsUserListLoading(true);
		if(username === ""){
			setUserList(null);
			setIsUserListLoading(false);
			return;
		}
			
    let users = await getUserList(directoryId, username);
		setUserList(users);
		setIsUserListLoading(false);
  }, 500)
	
	const cloneUserGroups = async (users) => {
		let existingSelectedUsers = [...clonedUsers];
		setClonedUsers(users);

		//clone groups associated with newly selected user
		if(existingSelectedUsers.length < users.length){
			let userGroupList = await getUserGroups(users.at(-1)?.username)
			setClonedGroups(prev => prev.concat(
				userGroupList.map(group => {
					group.checked = true; 
					group.type = "clonedGroup"
					return group
				})
			))
		} else if(existingSelectedUsers.length > users.length) {
			let currentUserNames = users.map(user => user.username);
			let updatedClonedGroups = clonedGroups.filter(group => currentUserNames.includes(group.username))
			setClonedGroups(updatedClonedGroups);
		}
	};

	const checkGroups = (groupType, groupName, checked) => {
		if(groupType === "addedGroup"){
			setSelectedGroups(prev => ({
					...prev, 
					addedGroups: prev.addedGroups.map(item => {
						if(item.label === groupName )
							item.checked = checked
						return item
					})
				})
			)
		} 

		if(groupType === "clonedGroup"){
			setSelectedGroups(prev => ({
					...prev, 
					clonedGroups: prev.clonedGroups.map(item => {
						if(item.label === groupName )
							item.checked = checked
						return item
					})
				})
			)
		}
		
	}

  return (
    <Stack
      sx={{
        flexDirection: "row",
        flexGrow: 1,
        width: "95%",
        alignSelf: "center",
      }}
    >
      <Stack sx={{ width: "100%", marginTop: 10, marginLeft: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: 600 }}> {"User directory and groups"} </Typography>
        <Typography sx={{ fontSize: 12, marginTop: 3, width: 600, fontWeight: 400 }}>
          Select the directory where the user should be created and assign a
          user group, or default values will be used. Alternatively, you can
          clone an existing user to inherit the directory and group settings.
        </Typography>

        <Stack sx={{ marginTop: 10, flexDirection: "row", columnGap: 50 }}>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}> {"Directory"} </Typography>
            <Autocomplete
              inputProps={{ name: "directory" }}
				value={selectedDirectory}
              options={directoryList.data ?? []}
              loading={directoryList.isLoading}
              onChange={(event, value) => setAttributes({ field: "directory", value: value })}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <CustomTextField {...params} variant="standard" />
              )}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}> {"Add Groups"} </Typography>
            <Autocomplete
				multiple
				isOptionEqualToValue={(option, value) => option.id === value.id}
              inputProps={{ name: "groups" }}
              options={isGroupListLoading ? [] : (groupList ?? [])}
              value={addedGroups}
              key={addedGroups.join(",")}
              loading={isGroupListLoading}
              onInputChange={(e, value) => searchGroups(value)}
              onChange={(e, value) => setAttributes({ field: "addedGroups", value: value }) }
              disabled={!selectedDirectory?.id}
              noOptionsText={ groupList === null ? "search group name..." : "No group found" }
              limitTags={2}
              getLimitTagsText={(more) => `+${more} more`}
              sx={{ minWidth: 300 }}
              renderInput={(params) => (
                <CustomTextField {...params} variant="standard" />
              )}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}> {"Clone User Groups"} </Typography>
            <Autocomplete
				multiple
				isOptionEqualToValue={(option, value) => option.id === value.id}

              	inputProps={{ name: "users" }}
				value={clonedUsers}
				key={clonedUsers.join(',')}
              	options={isUserListLoading ? [] : (userList ?? [])}
             	disabled={!selectedDirectory?.id}
              	loading={isUserListLoading}
				onInputChange={(e, value) => searchUsers(selectedDirectory?.id, value)}
				onChange={(e, value) => cloneUserGroups(value)}
              	noOptionsText={ userList === null ? "search username..." : "No user found"}
				limitTags={1}
              	getLimitTagsText={(more) => `+${more} more`}
              	disablePortal
              	sx={{ minWidth: 300,}}
              	renderInput={(params) => (
                	<CustomTextField {...params} variant="standard" />
              	)}
            />
          </Box>
        </Stack>
        <Stack sx={{ marginTop: 20 }}>
          {selectedGroups.addedGroups.length > 0 && (
						<>
							<Typography sx={{ fontSize: 14, fontWeight: 500 }}> {"Added Groups"} </Typography>
							<FormGroup
								sx={{
									flexDirection: "row",
									maxWidth: "95%",
									flexGrow: 1,
									height: "100%",
									marginTop: 5,
									columnGap: 10,
									rowGap: 10
								}}
							>
								{renderGroups(selectedGroups.addedGroups, checkGroups)}
							</FormGroup>
						</>
					)}

					{ selectedGroups.clonedGroups.length > 0 &&
						<>
							<Typography sx={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}> {"Cloned Groups"} </Typography>
								<FormGroup sx={{
										display: 'flex', 
										flexDirection:'row', 
										flexGrow:1, 
										rowGap: 10, 
										marginTop: 5,
										columnGap: 10, 
										maxWidth: "95%",
										height: "100%"
									}}
								>
									{renderGroups(selectedGroups.clonedGroups, checkGroups)}
								</FormGroup>
						</>
					}
					
        </Stack>
      </Stack>
    </Stack>
  );
};

const renderGroups = (groups, onChange) =>
  groups.map((group) => (
    <FormControlLabel
      label={group.label}
      sx={(theme) => ({
        backgroundColor: theme.palette.footer.main,
        padding: 0,
        borderRadius: 2,
        margin: 0,
        paddingRight: "9px",
      })}
      control={
        <Checkbox
          name={group.label}
          checked={group?.checked}
          onChange={(e, value) => onChange(group.type, group.label, value)}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
        />
      }
    />
  ));

export default React.memo(AddAttributes);
