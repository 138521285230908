import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";

const CustomSelect = (props, ref) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} >
      <Stack spacing={3}>
      <Typography>{props?.label}</Typography>
        <Select
          labelId={`${props.id + props.label}`}
          // onChange={props.onChange}
          ref={ref}
          {...props}
          onChange={(event) => props.handleSelectChange(event, props.id)}
          label=""
        >
          {props.options?.map((option) => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
          })}

        </Select>
      </Stack>
      <FormHelperText error={props.helperText}>{props.helperText}</FormHelperText>

    </FormControl>
  );
};

export default forwardRef(CustomSelect);
