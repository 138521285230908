import { Box } from "@mui/material";
import Drawer from "./Drawer";
import CustomList from "./CustomList";

const TabWithSidebar = (props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: '100%', ...props.sideBarStyle }}>
      <Drawer>
        <CustomList
          value={props.selectedItem}
          listNavItems={props.sideBarItems}
        />
      </Drawer>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          ...props.contentAreaStyle
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default TabWithSidebar;
