import { InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import { useUnauthorizedCrowdAccountCreation } from "../../../api/selfServe";
import { emailValidation } from "../../../utils/validations";
import { useHookForm } from "../../../utils/helper";
import LoadingButton from "../../../components/LoadingButton";

const CrowdAccountRequest = (props) => {
    const crowAccountRequest = useUnauthorizedCrowdAccountCreation();
    const { registerField, handleSubmit} = useHookForm();

    return (
      <Stack spacing={5}>
        <Typography variant="body3">
          A Crowd account is needed in order to log in to tools like the
          Networks{" "}
          <Link href="https://jira.tvac.bt.co.uk" target="_blank" underline="hover" sx={{ fontWeight: 800 }} >
            Jira
          </Link>
          &nbsp; & &nbsp;
          <Link href="https://wiki.tvac.bt.co.uk" target="_blank" underline="hover" sx={{ fontWeight: 800 }} >
            Wiki
          </Link>
        </Typography>

        <Typography variant="body3">
          If you have a BT/EE email, submit it below to request a Crowd account.
          You'll receive setup details via email
        </Typography>
        <br />

        <form onSubmit={ handleSubmit(crowAccountRequest.mutate) }>
          <TextField
            size="medium"
            placeholder="Email"
            {...registerField("email", {...emailValidation})}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment sx={{ margin: 0 }} position="start">
                    <LoadingButton
                      variant="contained"
                      disableElevation
                      sx={{ minHeight: "34px", minWidth: "86px" }}
                      isLoading={crowAccountRequest.isPending}
                      type="submit"
                    >
                      Request
                    </LoadingButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </form>
      </Stack>
    );

}

export default CrowdAccountRequest;