import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import React, { useEffect, useState } from "react";
import TabWithSidebar from "../../../components/TabWithSidebar.jsx";
import RequestHistory from "../SelfServeTab/options/RequestHistory.jsx";
import BulkCrowdAccount from "./BulkCrowdAccount";
import { useAdminRequestHistory, useFetchRequests } from "../../../api/selfServe.js";
import Approvals from "./Approvals";
import { useSearchParams } from "react-router-dom";

const AdminTab = (props) => {
  const pendingRequests = useFetchRequests();
  const [searchParams] = useSearchParams();
  const requestedOption = searchParams.get("option"); 

  useEffect(() => {
    pendingRequests.refetch();
  },[])
  
  const OPTIONS = {
    REQUEST_HISTORY: 0,
    BULK_CROWD_ACCOUNT:1,
    APPROVALS: 2
  };

  const [selectedOption, setSelectedOption] = useState(Number(requestedOption) ?? OPTIONS.REQUEST_HISTORY);

  function renderOptionView(selectedOption) {
    let optionView = <></>;
    switch (selectedOption) {
      case OPTIONS.REQUEST_HISTORY:
        optionView = <RequestHistory useRequestHistory={useAdminRequestHistory} isAdmin={true}/>;
        break;
      case OPTIONS.BULK_CROWD_ACCOUNT:
        optionView = <BulkCrowdAccount />;
        break;
        case OPTIONS.APPROVALS:
          optionView = <Approvals />;
          break;        
      default:
        break;
    }

    return optionView;
  }

  const menuOptions = [
    {
      text: "Request History",
      id: OPTIONS.REQUEST_HISTORY,
      icon: <DashboardOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.REQUEST_HISTORY),
    },
    {
      text: "Bulk Crowd Account",
      id: OPTIONS.BULK_CROWD_ACCOUNT,
      icon: <PersonAddAltOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.BULK_CROWD_ACCOUNT),
      
    },
    {
      text: "Approvals",
      id: OPTIONS.APPROVALS,
      icon: <TaskOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.APPROVALS),
      badgeContent: pendingRequests.data?.length ?? 0
      
    },
  ];

  return (
    <TabWithSidebar sideBarItems={menuOptions} selectedItem={selectedOption}>
      {renderOptionView(selectedOption)}
    </TabWithSidebar>
  );
};

export default React.memo(AdminTab);
