import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";

import React, { useRef, useState } from "react";
import AddUsers from "./crowdsteps/AddUsers";
import AddAttributes from "./crowdsteps/AddAttributes";
import CreateAccount from "./crowdsteps/CreateAccount";
import { useCreateUserAccounts } from "../../../../api/selfServe";
import { DEFAULT_DIRECTORY } from "../../../../config/constants/selfServe";

const BulkCrowdAccount = (props) => {
  const [hasError, setHasError] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const steps = ["Add User Details", "Add Attributes", "Create Account"];
  const [completed, setCompleted] = useState(false);
  const userAccountRequest = useCreateUserAccounts();
  const [userData, setUserData] = useState([]);
  const userAttributes = useRef({
    selectedDirectory: "",
    clonedUsers: [],
    selectedGroups: {
      addedGroups: [],
      clonedGroups: [],
    },
  });
  const [requestResponse, setRequestResponse] = useState(null);

  const handleNext = () => {
    if (hasError) return;
    setCompletedSteps(prev => ({...prev, [currentStepIndex]: true}))
    setCurrentStepIndex((prev) => prev + 1);
  };

  const handleBack = () => {
    setCompletedSteps(prev => ({...prev, [currentStepIndex]: false}))
    setCurrentStepIndex((prev) => prev - 1);
  };

  const createUserAccounts = async () => {
    setCompleted(true);
    setCompletedSteps(prev => ({...prev, [currentStepIndex]: true}))
    let users = userData.map(item => ({
      username: item.userName, 
      firstName: item.firstName, 
      lastName: item.lastName, 
      email: item.email
    }));

    let selectedGroups = [...new Set(
      [
        ...userAttributes.current.selectedGroups.addedGroups,
        ...userAttributes.current.selectedGroups.clonedGroups
      ]
        .filter(item => item.checked)
        .map(item => item.label)
    )];

    const directoryId = userAttributes.current?.selectedDirectory?.id ?? DEFAULT_DIRECTORY.id

    const data = {
      users: users,
      groups: selectedGroups,
      directoryId: directoryId,
    }

    let response = await userAccountRequest.mutateAsync(data);
    setRequestResponse(response.data)
  }

  const getAttributes = (attributes) => {
    userAttributes.current = attributes;
  }

  const renderStep = (step) => {
    switch (step) {
      case 0: return (<AddUsers userData={userData} setUserData={setUserData} setError={setHasError} />);
      case 1:
        return <AddAttributes getAttributes={getAttributes} userAttributes={userAttributes.current}/>;
      case 2:
        return <CreateAccount 
                  userData={userData} 
                  userAttributes={userAttributes.current} 
                  completed={completed} 
                  isLoading={userAccountRequest.isPending}
                  requestResponse={requestResponse}
                />;
      default:
        <></>;
        break;
    }
  };

  return (
    <>
      <Stack sx={{ flexGrow: 1 }}>
        <StepperDisplay steps={steps} currentStepIndex={currentStepIndex} completedSteps={completedSteps}>
          {renderStep(currentStepIndex)}
        </StepperDisplay>
        <StepperButtons 
          handleBack={handleBack}
          handleNext={currentStepIndex === steps.length - 1 ? createUserAccounts : handleNext}
          hasError={hasError}
          hidePrev={currentStepIndex === 0 }
          nextLabel={currentStepIndex === steps.length - 1 ? "Create Accounts": "Next"}
          disableNext={userData.length === 0}
          hidden={completed}
        />
      </Stack>
    </>
  );
};

export default React.memo(BulkCrowdAccount);


const StepperDisplay = (props) => {
  return(
    <>
      <Stack sx={{ alignSelf: "center", width: "55%", marginTop: 15 }}>
        <Stepper activeStep={props.currentStepIndex}>
          {props.steps.map((label, idx) => (
            <Step key={label} completed={props.completedSteps[idx]}>
            <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack sx={{ flexGrow: 1, marginTop: 10 }}>
      {props.children}
    </Stack>
   </>
  );
}

const StepperButtons = (props) => {
  return(
    props.hidden
      ? <></>
      : (
          <Stack sx={{flexDirection: "row", justifyContent: "space-between", marginTop: 3, width: "95%", alignSelf: "center",}}>
            <Button sx={{ visibility: props.hidePrev ? "hidden" : "" }} onClick={props.handleBack}> {"Back"} </Button>
            <Stack sx={{ flexDirection: "row" }}>
              <Tooltip title={props.hasError ? "Error in user data" : ""} placement="left">
                <span>
                  <Button
                    sx={{ color: props.hasError ? "red" : "", pointerEvents: props.hasError ? "none" : "",}}
                    disabled={props.disableNext}
                    onClick={props.handleNext}
                    {...(props.hasError && { endIcon: <ErrorOutlineIcon /> })}
                  >
                    {props.nextLabel}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
      )        
  );
}