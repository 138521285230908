import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "black" : "rgba(255,255,255,0.85)",
  backgroundColor: `${theme.mixins.MuiDataGrid.containerBackground}`,

  "& .MuiDataGrid-iconSeparator": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.26)"
        : "rgba(255, 255, 255, 0.3)",
  },

  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
    {
      outline: `1px solid ${
        theme.palette.mode === "light"
          ? "rgba(0, 0, 0, 0.26)"
          : "rgba(255, 255, 255, 0.3)"
      }`,
      borderRadius: "2px",
    },
  "& .MuiDataGrid-cell": {
    display: "flex",
    alignItems: "center",
    borderTop: 0,
    paddingTop: 6.8,
    paddingBottom: 6.8,
  },
  "& .MuiDataGrid-row": {
    backgroundColor: `${
      theme.palette.mode === "light" ? "#e8e8e8" : "#191f25"
    }`,
    border: 0,
    borderBottom: `1px solid ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.26)"
        : "rgba(255, 255, 255, 0.3)"
    }`,
  },
}));

export default CustomDataGrid;
