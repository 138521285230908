import { useDispatch } from "react-redux";
import axiosClient from "../config/axios"
import { ADMIN_REQUEST_HISTORY_URL, APPROVE_REQUESTS, CREATE_CROWD_ACCOUNT_URL, CREATE_CROWD_ACCOUNT_URL_UNAUTHORIZED, CREATE_JIRA_PROJECT_URL, DIRECTORY_LIST_URL, FETCH_REQUESTS, GROUP_LIST_URL, REJECT_REQUESTS, REQUEST_HISTORY_URL, USER__GROUP_LIST_URL, USER_ACCOUNT_CREATION_URL, USER_LIST_URL } from "../config/constants/selfServe"
import { useMutation, useQuery } from "@tanstack/react-query";
import { setAlert } from "../store/appStore";
import { getUserDetailsFromMail } from "../utils/helper";
import { useSelector } from "react-redux";


export const useCrowdAccountCreation = () => {
    const dispatchAction = useDispatch();
    const user = useSelector((state) => state.app.user);
    return useMutation({
        mutationFn:  async (data) => {
            let emailId = data?.email;
            const {firstName, lastName, userName} = getUserDetailsFromMail(emailId);
            let payload = 
                {
                username: userName,
                firstName: firstName,
                lastName: lastName,
                email: emailId,
                requestedBy: user?.email
                }
    
            const response = await axiosClient.post(CREATE_CROWD_ACCOUNT_URL, payload);
            if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
    },
    })
};

export const useUnauthorizedCrowdAccountCreation = () => {
    const dispatchAction = useDispatch();
    const user = useSelector((state) => state.app.user);
    return useMutation({
        mutationFn:  async (data) => {
            let emailId = data?.email;
            const {firstName, lastName, userName} = getUserDetailsFromMail(emailId);
            let payload = 
                {
                username: userName,
                firstName: firstName,
                lastName: lastName,
                email: emailId,
                requestedBy: user?.email ?? "self"
                }
    
            const response = await axiosClient.post(CREATE_CROWD_ACCOUNT_URL_UNAUTHORIZED, payload);
            if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
    },
    })
};

export const useJiraProjectCreationRequest = () => {
    const dispatchAction = useDispatch();
    const user = useSelector((state) => state.app.user);

    return useMutation({
        mutationFn: async (data) => {

            const {userName: requestingUsername} = getUserDetailsFromMail(data.email);
            const {userName: leadUsername} = getUserDetailsFromMail(data.projectLeadEmail);
            const projectTypeKey = data.projectType !== "XRAY" ? "SOFTWARE" : "BUSINESS"
            
            const payload = {
                email : data.email,
                requestingUsername: requestingUsername,
                projectLeadEmail : data.projectLeadEmail,
                leadUsername: leadUsername,
                projectName : data.projectName,
                projectKey : data.projectKey.toUpperCase(),
                projectTypeKey : projectTypeKey,
                projectTemplateKey: data.projectType,
                boardType: data.boardType,
                requestedBy: user?.email
              };
  
              const response = await axiosClient.post(CREATE_JIRA_PROJECT_URL, payload);
              if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
        }
    })
}

export const useRequestHistory = (userMailId) => {

    const fetchData = async () => {
        const queryParams = {
            requestedBy: userMailId,        
          };
        const response = await axiosClient.get(REQUEST_HISTORY_URL, {params: queryParams});
        const data = response?.data.data.map((request, idx) => {
            request.details = Object.keys(request.details).map(key => `${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}: ${request.details[key]}`).join("\n")
            request.id = idx
            return request;
        }).sort((request1, request2) => {
            const dateA = new Date(request1.dateTime);
            const dateB = new Date(request2.dateTime);
            return dateB - dateA;
          });
        return data;
    } 
    return useQuery({ queryKey: ['requestHistory'],  queryFn: fetchData, refetchOnMount: true, refetchOnWindowFocus:  false})
}

export const useAdminRequestHistory = (userMailId) => {
    const fetchData = async () => {
        const queryParams = {
            requestedBy: userMailId, 
            isAdmin: true       
          };
        const response = await axiosClient.get(ADMIN_REQUEST_HISTORY_URL, {params: queryParams});
        const data = response?.data.data.map((request, idx) => {
            request.id = idx;
            return request;
        }).sort((request1, request2) => {
            const dateA = new Date(request1.dateTime);
            const dateB = new Date(request2.dateTime);
            return dateB - dateA;
          });
        return data;
    } 
    return useQuery({ queryKey: ['adminRequestHistory'],  queryFn: fetchData, refetchOnMount: true,  refetchOnWindowFocus:  false})
}

export const useDirectoryList = () => {
    const fetchData = async () => {
        const response = await axiosClient.get(DIRECTORY_LIST_URL);
        response?.data.data.forEach(item => {
            item.label = item.displayName
            delete item.displayName
        });
        return response?.data.data?.filter(item => item.id !== 163841);
    } 
    return useQuery({ queryKey: ['directoryList'],  queryFn: fetchData})
}

export const getGroupList = async (directoryId, groupName) => {
        const response = await axiosClient.get(GROUP_LIST_URL, {
            params: {
                directoryId: directoryId,
                search: groupName
            }
        });
        response?.data.data.forEach((item, idx) => {
            item.label = item.name
            item.id = item.name
            delete item.name
        });
        return response?.data.data;
} 

export const getUserList = async (directoryId, username) => {
    const response = await axiosClient.get(USER_LIST_URL, {
        params: {
            directoryId: directoryId,
            username: username
        }
    });
    response?.data.data.forEach((item, idx) => {
        item.label = `${item.displayName} (${item.username})`
    });
    return response?.data.data;
} 

export const getUserGroups = async (username) => {
    const response = await axiosClient.get(USER__GROUP_LIST_URL, {
        params:{
            username: username
        }
    });
    response.data.data.groups = response.data.data.groups.map((item, idx) =>{

        return ({...item, username: username, id: item.name, label: item.name})
    });
    
    return response.data.data.groups;
}

export const useCreateUserAccounts = () => {
    const dispatchAction = useDispatch();
    const user = useSelector((state) => state.app.user);

    return useMutation({
        mutationFn:  async (data) => {
            
            const payload = {
                'users': data.users,
                'groups': data.groups,
                'directoryId': data.directoryId,
                'requestedBy': user?.email
            };
            const response = await axiosClient.post(USER_ACCOUNT_CREATION_URL, payload);
            if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
    },
    })
}

export const useFetchRequests = (status) => {
   const fetchData = async () => {
        const queryParams = {
            'status': 'pending',        
        };
        const response = await axiosClient.get(FETCH_REQUESTS, {params: queryParams});
        const data = response?.data.data.sort((request1, request2) => {
            const dateA = new Date(request1.dateTime);
            const dateB = new Date(request2.dateTime);
            return dateB - dateA;
        });
        return data;
    } 
    return useQuery({ queryKey: ['fetchRequests'],  queryFn: fetchData, refetchOnMount: false, refetchOnWindowFocus:  false})
}

export const useApproveRequest = () => {
    const dispatchAction = useDispatch();
    return useMutation({
        mutationFn:  async (data) => {
            const payload = {
                'requestId': data.requestId,
                'requestType': data.requestType,
                'requestedBy': data.requestedBy
            };
            const response = await axiosClient.post(APPROVE_REQUESTS, payload);
            if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
    },
    })
}

export const useRejectRequest = () => {
    const dispatchAction = useDispatch();
    return useMutation({
        mutationFn:  async (data) => {
            const payload = {
                'requestId': data.requestId,
                'requestType': data.requestType,
                'requestedBy': data.requestedBy
            };
            const response = await axiosClient.post(REJECT_REQUESTS, payload);
            if (response.data.status === 200) {
                dispatchAction(setAlert({
                message: response.data.message,
                alertLevel: "success"
                }));
            }
            return response?.data;
    },
    })
}