import { Box, Link, Popover, Stack, Typography } from "@mui/material";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import { useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { toSentenceCase } from "../../../../utils/helper";

const RequestHistory = (props) => {
  const user = useSelector((state) => state.app.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverData = useRef({});



  const requestHistory = props.useRequestHistory(user?.email);

  const columns = [
    { field: "date", headerName: "Date", width: 90},
    { field: "requestId", headerName: "Request Id", flex: 1 },
    { field: "requestType", headerName: "Request Type", flex: 1 },
    { field: "details", headerName: "Request Details", flex: 1, renderCell: (data) => (<div style={{whiteSpace: "pre-line"}}>{data.value}</div>)},
    { field: "status", headerName: "Status", width: 90 },
  ];

  const adminColumns = [
    { field: "date", headerName: "Date", width: 90},
    { field: "requestId", headerName: "Request Id", flex: 1 },
    { field: "requestType", headerName: "Request Type", flex: 1 },
    { field: "details", headerName: "Request Details", flex: 1, renderCell: (data) => (<Link underline="hover" sx={{cursor:'pointer' }} onClick={(e) => handleClick(e, data)}>{"Details"}</Link>)},
    { field: "status", headerName: "Status", width: 90 },
  ];

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    popoverData.current = data
  };

  const handleClose = () => {
    setAnchorEl(null);
    popoverData.current = {}
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "95%",
          height: {xs: 410, sm: 400, md: 400, lg: 400, xl: 501}
        }}
      >
        <CustomDataGrid
          rows={requestHistory.data}
          columns={props.isAdmin ? adminColumns : columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
          disableRowSelectionOnClick
          loading={requestHistory.isLoading}
          getRowHeight={() => 'auto'}
          selectionModel={[]}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
        
        />
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.mode === "light" ? "#d3d3d3" : "#3607a7",
            padding: 8,
            borderRadius: "10px",
            minWidth: "200px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", 
          }),
        }}
      >
        {
          popoverData.current?.row?.requestType === "JIRA PROJECT CREATION"
            ? renderJiraRequest(popoverData.current?.value)
            : renderCrowdAccountRequest(popoverData.current?.value)
        }
      </Popover>
    </Box>
  );
};

export default React.memo(RequestHistory);

const renderCrowdAccountRequest = (data={}) => {
  return(
    <>
        <Typography sx={{ p: 2, fontWeight: 500, fontSize: 14}}>Request Details</Typography>
        {
          Object.entries(data)?.map(item => {
            if(!item[1].length)
              return;
            return(
              <div style={{marginTop: 4}}>
              <Typography sx={{ p: 2, fontWeight: 500}}>{toSentenceCase(item[0]?.split("_")?.join(" "))}</Typography>
              {
                
                item[1]?.map(item => {
                  return(
                    <Stack sx={{flexDirection: 'row', borderRadius: "3px", border: '1px solid'}}>
                      <Typography sx={{flexGrow: 1, padding: 5}}>{item?.email}</Typography>
                      {item?.reason && (
                        <Typography sx={{borderLeft: "1px solid", padding: 5}}>
                          {JSON.parse(item?.reason)?.message}
                        </Typography>)
                      }
                    </Stack>
                  )
                })
              }          
              </div>
            );
          })
        }    
    </>
  );
}

const renderJiraRequest = (data={}) => {
  return(
    <>
        <Typography sx={{ p: 2, fontWeight: 500, fontSize: 14}}>Request Details</Typography>
        {
          Object.entries(data)?.map(item => {
            return(
              <div style={{marginTop: 4}}>
              <Typography sx={{ p: 2, fontWeight: 500}}>{toSentenceCase(item[0])}</Typography>
              <Typography sx={{flexGrow: 1, padding: 5}}>{item[1]}</Typography>
              </div>
            );
          })
        }    
    </>
  );
}
