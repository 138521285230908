import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import LoadingButton from "./LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const DragandDropZone = (props) => {
  const { open, onClose = () => {}, accept = [], getFiles } = props;
  const [files, setFiles] = useState([]);

  const filterAcceptedFiles = (files) => {

    const acceptedExtensions = [];
    const acceptedFileTypes = [];
    const acceptedFiles = [];

    accept.forEach(fileType => {
      if(fileType.extension) 
        acceptedExtensions.push(fileType.extension)

      if(fileType.type)
        acceptedFileTypes.push(fileType.type)

      if(fileType.extension && fileType.type)
        acceptedExtensions.push(fileType)
    })
    
    files.forEach(file => {
      const extension = file.name.split(".")[-1];
      const fileType = file.type;
      if(acceptedExtensions.includes(extension) || acceptedFileTypes.includes(fileType))
        acceptedFiles.push(file)
      
    })
    console.log(acceptedFiles);

    return acceptedFiles

  }

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const acceptedFiles = filterAcceptedFiles(files);
    setFiles(acceptedFiles);
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const acceptedFiles = filterAcceptedFiles(files);
    console.log(acceptedFiles)
    setFiles(acceptedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog open={open} >
      <DialogTitle sx={(theme) => ({backgroundColor: theme.palette.footer.main})}>Add CSV File</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={(theme) => ({backgroundColor: theme.palette.footer.main})}>
        <div>
          {/* File Drop Zone */}
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              width: "350px",
              height: "150px",
              border: "2px dashed #aaa",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              cursor: "pointer",
              padding: "10px",
            }}
          >
            Drag & Drop file here or &nbsp;
            <label
              htmlFor="fileInput"
              style={{ color: "#4d25ad", cursor: "pointer" }}
            >
              Click to Select
            </label>
          </div>

          {/* Hidden File Input */}
          <input
            id="fileInput"
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={handleFileSelect}
            accept={accept.map((fileType) => fileType?.extension ?? fileType ).toString()}
          />

          {/* Display Selected Files */}
          {files.length > 0 && (
            <div>
              <h4>Selected File:</h4>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>{" "}
      </DialogContent>
      <DialogActions sx={(theme) => ({backgroundColor: theme.palette.footer.main})}>
        <LoadingButton autoFocus onClick={() => {getFiles?.(files); onClose()}} disabled={files.length === 0} >
          Add File
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DragandDropZone;
