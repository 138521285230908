import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Box } from "@mui/material";
import { useState } from "react";
import NewCrowdAccountRequest from "./options/NewCrowdAccount.jsx";
import NewJiraProject from './options/NewJiraProject.jsx';
import RequestHistory from "./options/RequestHistory.jsx";
import LaunchIcon from '@mui/icons-material/Launch';
import TabWithSidebar from "../../../components/TabWithSidebar.jsx";
import { useRequestHistory } from "../../../api/selfServe.js";

const SelfServeTab = (props) => {
  
  const OPTIONS = {
    REQUEST_HISTORY: 0,
    NEW_CROWD_ACCOUNT: 1,
    RESET_CROWD_PASSWORD: 2,
    NEW_JIRA_PROJECT: 3,
  };

  const [selectedOption, setSelectedOption] = useState(OPTIONS.REQUEST_HISTORY);

  function renderOptionView(selectedOption) {
    let optionView = <></>;
    switch (selectedOption) {
      case OPTIONS.REQUEST_HISTORY:
        optionView = <RequestHistory useRequestHistory={useRequestHistory}/>;
        break;
      case OPTIONS.NEW_CROWD_ACCOUNT:
        optionView = <NewCrowdAccountRequest />;
        break;
      case OPTIONS.NEW_JIRA_PROJECT:
        optionView = <NewJiraProject />;
        break;
      default:
        break;
    }

    return optionView;
  }

  const menuOptions = [
    {
      text: "Request History",
      id: OPTIONS.REQUEST_HISTORY,
      icon: <DashboardOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.REQUEST_HISTORY),
    },
    {
      text: "New Crowd Account",
      id: OPTIONS.NEW_CROWD_ACCOUNT,
      icon: <PersonAddAltOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.NEW_CROWD_ACCOUNT),
    },
    {
      text: <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap:3}}>Reset Crowd Password <LaunchIcon sx={{ fontSize: 50 }}/></Box>,
      icon: <LockResetOutlinedIcon />,
      onClick: () => {window.open('https://crowd.tools.btcsp.co.uk/console/forgottenlogindetails!execute.action', '_blank')},
    },
    {
      text: "New Jira Project",
      id: OPTIONS.NEW_JIRA_PROJECT,
      icon: <AddchartOutlinedIcon />,
      onClick: () => setSelectedOption(OPTIONS.NEW_JIRA_PROJECT),
    },
  ]
  return (
    <TabWithSidebar sideBarItems={menuOptions} selectedItem={selectedOption}>
      {renderOptionView(selectedOption)}
    </TabWithSidebar>
  );
};

export default SelfServeTab;
