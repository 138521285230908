import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import {adminTabs, tabs} from "../../config/tabs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchAuthSession } from 'aws-amplify/auth';
import { ADMIN_GROUP_ID } from "../../config/constants/selfServe";
import { useSearchParams } from "react-router-dom";


const Dashboard = (props) => {
  const [currentTabs, setCurrrentTabs] = useState([...tabs]);
  const [searchParams] = useSearchParams();
  const requestedTab = searchParams.get("tab"); 
  const [selectedTab, setSelectedTab] = useState(() => {
    let index = currentTabs.findIndex(item => item.id === requestedTab)
    return (index === -1 ? 0 : index)
  })
  let navigate = useNavigate();
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    if(!user.isLoggedIn){
      navigate('/');
      return;
    }

    if(user.isLoggedIn){
      fetchAuthSession()
          .then((session) => {
            const accessToken = session.tokens.accessToken;
            if(accessToken?.payload['cognito:groups']?.includes(ADMIN_GROUP_ID))
              setCurrrentTabs(prev => [...prev, ...adminTabs])
          })
          .catch((error) => {
            console.error("Error fetching session:", error);
          });
    }
  },[user])

  function handleTabClick(e, value) {
    setSelectedTab(value);
  }



  return (
    user.isLoggedIn ?
    (<Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabClick}
        variant="fullWidth"
        sx={{
          background: "#342077",
          "& .MuiTabs-indicator": {
            backgroundColor: (theme) => theme.palette.tabIndicator.main,
          },
        }}
      >
        {currentTabs?.map((tab, idx) => {
          return (
            <Tab
              key={idx}
              label={tab.label}
              disableRipple
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#4d25ad",
                },
                "&.Mui-selected": {
                  backgroundColor: "#4d25ad",
                  color: "#ffffff",
                },
              }}
            />
          );
        })}
      </Tabs>
      <Box sx={{ flexGrow: 1, flexDirection: "row", display: "flex"}}>
        {currentTabs[selectedTab]?.component}
      </Box>
    </Box>)
    : <Box>Not logged in</Box>
  );
};

export default Dashboard;
