import { Stack, Typography } from "@mui/material";
import crowdlogo from "../../../../assets/crowdlogo.png";
import CustomTextField from "../../../../components/CustomTextField";
import { emailValidation } from "../../../../utils/validations";
import { useCrowdAccountCreation } from "../../../../api/selfServe";
import { useHookForm } from "../../../../utils/helper";
import LoadingButton from "../../../../components/LoadingButton";

const NewCrowdAccountRequest = (props) => {

  const { registerField, handleSubmit} = useHookForm();
  const crowAccountRequest = useCrowdAccountCreation();   
  
  return (
    <Stack sx={{ paddingTop: {xs:25, sm: 25, md: 25, lg: 25, xl: 43} }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", columnGap: 5 }} >
        <img src={crowdlogo} style={{ height: "40px" }} alt="crowd logo" />
        <Typography variant="h5">Request New Crowd Account</Typography>
      </Stack>
      <Stack sx={{ flexDirection: "column", rowGap: 10, alignItems: "center", marginTop: 25 }} component={"form"} onSubmit={handleSubmit(crowAccountRequest.mutate)}>
          <CustomTextField
            labelText={"Email"}
            {...registerField("email", {...emailValidation})}
          />
          <LoadingButton
            variant="contained"
            sx={{ width: "95px", height: "37px" }}
            type="submit"
            isLoading={crowAccountRequest.isPending}
          >
            Submit
          </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default NewCrowdAccountRequest;
