// self serve constants

export const BT_ALLOWED_ADDRESSES = [
    "@bt.com",
    "@ee.com",
    "@ee.co.uk",
    "openreach.co.uk", 
    "plus.net"
];

export const HTTPS_STATUS_CODE_KEYS = ["statusCode"];
export const DATA_KEYS = [""]
export const MESSAGE_KEYS = ["statusMessage","failure"];
export const SELF_SERVE_BASE_URL = process.env.REACT_APP_SELFSERVE_URL;

export const CREATE_CROWD_ACCOUNT_URL = SELF_SERVE_BASE_URL + "/selfserve/usercreation/request";
export const CREATE_CROWD_ACCOUNT_URL_UNAUTHORIZED = SELF_SERVE_BASE_URL + "/selfserve/unauthorized/usercreation/request";
export const CREATE_JIRA_PROJECT_URL = SELF_SERVE_BASE_URL + "/selfserve/projectcreation/request";
export const REQUEST_HISTORY_URL = SELF_SERVE_BASE_URL + "/selfserve/request/history"

// Admin apis
export const DIRECTORY_LIST_URL = SELF_SERVE_BASE_URL + "/admin/userdirectory/directory/list"
export const GROUP_LIST_URL = SELF_SERVE_BASE_URL + "/admin/userdirectory/group/list"
export const USER_LIST_URL = SELF_SERVE_BASE_URL + "/admin/userdirectory/user/list"
export const USER__GROUP_LIST_URL = SELF_SERVE_BASE_URL + "/admin/userdirectory/user/groups"
export const USER_ACCOUNT_CREATION_URL = SELF_SERVE_BASE_URL + "/admin/userdirectory/user/create"
export const ADMIN_REQUEST_HISTORY_URL = SELF_SERVE_BASE_URL + "/admin/request/history"
export const FETCH_REQUESTS = SELF_SERVE_BASE_URL + "/admin/requests/list"
export const APPROVE_REQUESTS = SELF_SERVE_BASE_URL + "/admin/requests/approve"
export const REJECT_REQUESTS = SELF_SERVE_BASE_URL + "/admin/requests/reject"




export const PATHS = {
    LANDING_PAGE: '/',
    DASHBOARD: '/dashboard'
}

export const ADMIN_GROUP_ID = 'admin'

export const FILE_TYPES = {
    CSV: {extension: '.csv', type: 'text/csv'}
}

export const DEFAULT_DIRECTORY = {id: 2490370, label: "BTGroup Users Directory"}
export const DEFAULT_GROUPS = ["BT", "Jira Users", "confluence-users"]