import axios from 'axios';
import { SELF_SERVE_BASE_URL } from './constants/selfServe';
import { transformSelfServeFailureResponse, transformSelfServeSuccessResponse } from '../utils/helper';
import { fetchAuthSession } from 'aws-amplify/auth';


// axios client for self serve apis
const axiosClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

axiosClient.interceptors.request.use((config) => {
    return fetchAuthSession()
    .then((session) => {
      const jwtToken = session.tokens.idToken?.toString();
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
      }
      return config;
    })
    .catch((error) => {
      console.error("Error fetching session:", error);
      return config; 
    });
  });
  

axiosClient.interceptors.response.use(response => {

    //transforms the api response format to a common format
    if (response.config.url.startsWith(SELF_SERVE_BASE_URL)) {
        const transformedResponse = transformSelfServeSuccessResponse(response);
        return transformedResponse;

    } else {

        return response;
    }

},
    error => {
        //transforms the api response format to a common format
        if (error.response?.config.url.startsWith(SELF_SERVE_BASE_URL)) {
            const transformedError = transformSelfServeFailureResponse(error);
            return Promise.reject(transformedError);

        } else {

            return Promise.reject(error);
        }

    })


export default axiosClient;