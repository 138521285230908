import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import LoadingButton from "../../../../components/LoadingButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { useEffect, useState } from "react";
import { useApproveRequest, useFetchRequests, useRejectRequest } from "../../../../api/selfServe";
import { useQueryClient } from "@tanstack/react-query";


const Approvals = (props) => {
    const requests = useFetchRequests();
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        if(refresh){
            requests.refetch?.()
            setRefresh(false)
        }
    },[refresh])

    return(
        <Stack sx={{flexGrow: 1, padding:10,}}>
            <Typography sx={{fontSize: 16, fontWeight: 500, marginTop: 5}}>
                Requests Pending Approval
            </Typography>
            <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop:8, color: 'white'}} />

            <Stack sx={{marginTop: 15, flexGrow: 1, flexDirection: 'row', columnGap: 5, rowGap: 5, flexWrap:'wrap'}}>
                {requests.isPending  
                ? (
                    <div style={{display: 'flex', flexGrow: 1,height: "100%", justifyContent: 'center', alignItems: 'center',}}>
                        <CircularProgress />
                    </div>
                    )
                : (!requests.isPending && !requests.data?.length) 
                    ? (
                        <div style={{display: 'flex', flexGrow: 1,height: "100%", justifyContent: 'center', alignItems: 'center',}}>
                            <Typography>No requests pending approval</Typography>
                        </div>

                    )
                    : requests.data?.map(request => <JiraRequestCard request={request} refresh={() => setRefresh(true)}/>)
                }
                

            </Stack>
        </Stack>
    );

}

export default React.memo(Approvals);

const JiraRequestCard = (props) => {
    const approveRequestApi = useApproveRequest();
    const rejectRequestApi = useRejectRequest();

    const approveRequest = async () => {
        const payload = {
            'requestId': props.request.requestId,
            'requestType': 'APPROVE_JIRA_PROJECT_REQUEST',
            'requestedBy': props.request.requestedBy
        };
        await approveRequestApi.mutateAsync(payload)
        props.refresh?.()
    }
    const rejectRequest = async () => {
        const payload = {
            'requestId': props.request.requestId,
            'requestType': 'REJECT_JIRA_PROJECT_REQUEST',
            'requestedBy': props.request.requestedBy
        };
        await rejectRequestApi.mutateAsync(payload)
        props.refresh?.()
    }    

    return(
        <Card variant="outlined" sx={(theme) => ({width: 400, height: 295, backgroundColor: theme.palette.mode === "dark" ? "#101720" : ""})}>
            <CardContent>
                <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems:'center'}}>
                <Typography sx={{fontWeight: 500,}}>{`#id: ${props.request.requestId}`}</Typography>
                <Typography></Typography>
                <Box sx={(theme) => ({
                    border: '1px solid #994822', 
                    color: '#994822',
                    background: theme.palette.mode === "dark" ? '#383633' : "#F5F3EE",
                    padding: 2, 
                    borderRadius: "50px", 
                    fontSize: 10,
                    fontWeight: 500,
                    width: 80,
                    textAlign: 'center',
                    marginLeft: 5,
                    height: 25
                })}>Jira Project</Box>
                </Stack>
                <Divider orientation="horizontal" variant="middle" flexItem sx={{marginTop:5}}/>
                <Stack sx={{flexDirection: 'row', justifyContent: 'center', marginTop: 10, columnGap: 15}}>
                    <Stack sx={{alignItems: 'flex-start', flexGrow: 1}}>
                        <Typography sx={{fontSize: 11, color: "#9e9e9e"}}>Project Name</Typography>
                        <Typography>{props.request.details.projectName}</Typography>
                    </Stack>
                    <Stack sx={{alignItems: 'flex-start', flexGrow: 1}}>
                        <Typography sx={{fontSize: 11, color: "#9e9e9e"}}>Project  Type</Typography>
                        <Typography>{props.request.details.projectType}</Typography>
                    
                    </Stack>
                </Stack>
                <Stack sx={{flexDirection: 'row', justifyContent: 'center', marginTop: 20, columnGap: 15}}>
                    <Stack  sx={{alignItems: 'flex-start', flexGrow: 1}}>
                    <Typography sx={{fontSize: 11, color: "#9e9e9e"}}>Lead Email</Typography>
                    <Typography>{props.request.details.projectLead}</Typography>
                        
                    </Stack>

                    <Stack  sx={{alignItems: 'flex-start', flexGrow: 1}}>
                    <Typography sx={{fontSize: 11, color: "#9e9e9e"}}>Request  by</Typography>
                    <Typography>{props.request.requestedBy}</Typography>
                        
                    </Stack>
                </Stack>

            </CardContent>
            <CardActions sx={{marginTop: 15, columnGap: 3, padding: '10px 20px'}}>
                <LoadingButton 
                    startIcon={<HighlightOffIcon />} 
                    disableElevation variant="contained" 
                    elevation={0} sx={{flexGrow: 0.5, backgroundColor: '#363538'}}
                    isLoading={rejectRequestApi.isPending}
                    disabled={approveRequestApi.isPending}
                    onClick={rejectRequest}
                >
                     Reject
                </LoadingButton>
                <LoadingButton  
                    startIcon={<CheckCircleOutlineIcon />} 
                    disableElevation variant="contained" 
                    sx={{flexGrow: 0.5, backgroundColor: '#0253f4'}}
                    isLoading={approveRequestApi.isPending}
                    onClick={approveRequest}
                >
                    Approve
                </LoadingButton>
            </CardActions>
        </Card>        
    );
}