import { Box, CircularProgress, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import CustomDataGrid from "../../../../../components/CustomDataGrid";
import { useState } from "react";
import { DEFAULT_DIRECTORY } from "../../../../../config/constants/selfServe";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CreateAccount = (props) => {

  const columns = [
    { field: "id", headerName: "No.", type: 'integer', width: 50, align: 'center'},
    { field: "email", headerName: "Email Id", type: "string", flex: 1 },
    { field: "userName", headerName: "Username", type: "string", flex: 1 },
    { field: "firstName", headerName: "First Name", type: "string", flex: 1 },
    { field: "lastName", headerName: "Last Name", type: "string", flex: 1 },
    { field: "directory", headerName: "Directory", type: "string", flex: 1 },
    { field: "groups", headerName: "Groups", type: "string", flex: 1,     sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }, },
  ];

  let selectedGroups = [
    ...props.userAttributes.selectedGroups.addedGroups.filter(item => item.checked),
    ...props.userAttributes.selectedGroups.clonedGroups.filter(item => item.checked)
  ];
  selectedGroups = [...new Set(selectedGroups.map(group => group.label))];
  const users = props.userData?.map((item, idx) => {
    item.id = idx + 1
    item.directory = props.userAttributes.selectedDirectory?.label ?? DEFAULT_DIRECTORY.label
    item.groups = selectedGroups.join(", \n")
    return item;
  });

  return (
    <Stack
      sx={{
        flexDirection: "row",
        flexGrow: 1,
        width: "95%",
        alignSelf: "center",
      }}
    >
        <Stack sx={{ width: "100%" , marginTop: 1, marginLeft: 5}}>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
            {props.completed ? "" : "Preview & create"}
          </Typography>
          {
            
            props.completed 
              ? <CompletedScreen isLoading={props?.isLoading} requestResponse={props.requestResponse}/> 
              :
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignSelf: "center",
                  marginTop: 10,
                  height: { xs: 410, sm: 400, md: 400, lg: 370, xl: 501 },
                }}
              >
                <CustomDataGrid
                  rows={users}
                  columns={columns}
                  disableRowSelectionOnClick
                  sx={(theme) => ({
                    border: "none",
                    backgroundColor:
                      theme.palette.mode === "light" ? "#e8e8e8" : "#191f25",
                    "& .MuiDataGrid-cell": { border: "none" },
                    "& .MuiDataGrid-columnHeaders": {
                      borderBottom: "none",
                      backgroundColor: "red",
                    },
                    "& .MuiDataGrid-row": { borderBottom: "none" },
                    "& .MuiDataGrid-filler": { display: "none" },
                    "& .MuiDataGrid-columnSeparator": { display: "none" },
                    "& .DataError": { backgroundColor: "#f44336" },
                    "& .MuiDataGrid-cell:focus": { outline: "none" },
                  })}
                />
              </Box>
          }
        </Stack>
    </Stack>
  );
};


const CompletedScreen = (props) => {
  return(
    <Stack sx={{'alignItems': 'center', flexGrow: 1}}>
      <Stack sx={{'alignItems': 'center', justifyContent: 'flex-start', flexGrow: 1}}>

        <Typography sx={{fontSize: 15, fontWeight: 500, marginTop: 15}}>Request Status</Typography>
          <Box sx={{marginTop: 20}}>
            {
              props.isLoading
                ? <CircularProgress />
                : (
                  <>
                    { props.requestResponse?.success?.length > 0 && (
                        <MessageCard>
                          <Typography>{`Success`}</Typography>
                          <Typography sx={{ fontWeight: 500, marginLeft:'auto', marginRight: 5,}}>{`${props.requestResponse?.success?.length}`}</Typography>
                          <CheckCircleIcon sx={{color: 'green'}}/>
                        </MessageCard>
                      )
                    }

                    { props.requestResponse?.failed?.length > 0 && (
                        <MessageCard>
                          <Typography sx={{ fontWeight: 500}}>{`Failed`}</Typography>
                          <Typography sx={{ fontWeight: 500, marginLeft:'auto', marginRight: 5,}}>{`${props.requestResponse?.failed?.length}`}</Typography>
                          <HighlightOffIcon sx={{color: 'red'}}/>
                        </MessageCard>
                      )
                    }  
                    
                    { props.requestResponse?.add_group_failures?.length > 0 && (
                        <MessageCard>
                          <Typography>{`Groups failed to assign:  ${props.requestResponse?.add_group_failures?.length}`}</Typography>
                          <HighlightOffIcon sx={{color: 'red'}}/>
                        </MessageCard>
                      )
                    } 
                    
                    { props.requestResponse?.password_reset_mail_failures?.length > 0 && (
                        <MessageCard>
                          <Typography>{`Password reset mail failed to send:  ${props.requestResponse?.password_reset_mail_failures?.length}`}</Typography>
                          <HighlightOffIcon sx={{color: 'red'}}/>
                        </MessageCard>
                      )
                    }    
                  </>
                )
            }                   
          </Box>
        <Typography sx={{marginTop: 'auto', marginBottom: 20}}>Check request history for more details</Typography>
      </Stack>
    </Stack>
  )
}
export default CreateAccount;

const MessageCard = (props) => {
  return(
    <Paper sx={(theme)=>({
      height: 60, 
      width: 500, 
      marginTop: 5, 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      padding: 10,
      backgroundColor: theme.palette.footer.main
      })}
    >
      {props.children}

    </Paper>
  );
} 
