import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertMessage: null,
    alertLevel: "info",
    user: {isLoggedIn: false},
    isLoadingUserData: false
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAlert: (state, action) => {
            state.alertMessage = action.payload.message;
            state.alertLevel = action.payload.alertLevel
        },

        clearAlert: (state) => {
            state.alertMessage = null;
            state.alertLevel = "info";
        },

        setUser: (state, action) => {
                if(action.payload.data){
                    state.user = {...action.payload.data};
                    state.user.isLoggedIn = true;
                }
        },

        clearUser: (state, action) => {
            state.user = {isLoggedIn: false}
        },

        setLoadingUserData: (state, action) => {
            state.isLoadingUserData = action.payload.isLoadingUserData;
        }
    }

});

export const {setAlert, clearAlert, setUser, clearUser, setLoadingUserData} = appSlice.actions;
export default appSlice;