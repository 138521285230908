import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import "./config/i18n";
import queryClient from "./config/queryClient";
import theme from "./config/theme";
import router from "./routes/routes";
import store from "./store/store";
import { Provider } from 'react-redux';


/*
 * Main application component.
 * - ErrorBoundary: Catches and handles apllication errors.
 * - QueryClientProvider: Manages server state with React Query.
 * - ThemeProvider: Applies the app's custom theme.
 * - CssBaseline: Resets and normalizes CSS across browsers with MUI's CssBaseline.
 * - RouterProvider: Provides the routing configuration using React Router;
 *   Define routes in the 'router' object to map components to specific paths'.
 */

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme} defaultMode="system">
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
