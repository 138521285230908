import AdminTab from "../pages/Dashboard/AdminTab";
import SelfServeTab from "../pages/Dashboard/SelfServeTab";
import WelcomeScreen from "../pages/Dashboard/WelcomeScreen";

export const tabs = [
    {
        label: 'Welcome',
        id: 'welcome',
        component: <WelcomeScreen />
    },
    {
        label: 'Self Serve',
        id: 'selfserve',
        component: <SelfServeTab />

    },
    ];

export const adminTabs = [
    {
        label: 'Tools Admin',
        id: 'admin',
        component: <AdminTab />
    },
]

