import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { signInWithRedirect } from "aws-amplify/auth"
import logo from "../../../assets/btlogo.png";

const LogIn = (props) => {

  const login = async () => {
    try{

      const providerName = process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER_NAME
      const data = await signInWithRedirect({ provider: {
        custom: providerName
      }});

    }catch(e){
      console.log(e)
    }
  }

    return(
        <Box
        sx={{
          width: "300px",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          rowGap: 10,
        }}
      >
        <Typography variant="h5">
          Sign in to your Tools Account
        </Typography>
        <Paper
          sx={{
            padding: 12,
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "213px",
          }}
        >
          <Stack 
          spacing={10}
          sx={{
            alignItems:'center', border:0, height: 'auto', width: 'auto',
            margin:'auto'
            }}>
            <Typography>
            Please log in using your Crowd Single Sign-On (SSO) credentials to get started. Your login ensures secure and unified access to streamline your workflow and enhance productivity.
            </Typography>
            <Button
            variant="contained"
            disableElevation
            sx={{ width: "100px"}}
            onClick={login}
            startIcon={<img src={logo} alt="" height={20} width={20}/>}
          >
            Log in
          </Button>
          </Stack>


        </Paper>
      </Box>
    );
};

export default LogIn;