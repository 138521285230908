import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const config =   {Auth: {
  Cognito: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    region: "eu-west-1",
    passwordFormat: {
      minLength: 8,
      requireLowercase: true,
      requireUppercase: true,
      requireNumbers: true,
      requireSpecialCharacters: true,
    },
    signUpVerificationMethod: "code",
    loginWith:{
      oauth: {
        domain: `${process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN}.auth.eu-west-1.amazoncognito.com`,
        clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        scopes: [
          "openid",
          "email",
          "profile",
          "phone",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN, "https://localhost:3000"],
        redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT, "https://localhost:3000"],
        responseType: "code",
      },
    } 
  },


}}
Amplify.configure(config);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
