import { Grid2, Stack, Typography } from "@mui/material";
import jiralogo from "../../../../assets/jiralogo.png";
import CustomTextField from "../../../../components/CustomTextField";
import CustomSelect from "../../../../components/CustomSelect";
import { useHookForm } from "../../../../utils/helper";
import LoadingButton from "../../../../components/LoadingButton";
import { useJiraProjectCreationRequest } from "../../../../api/selfServe";

const NewJiraProject = (props) => {
  const { registerField, handleSubmit, setValue, watch} = useHookForm();
  const projectType = watch("projectType")
  const jiraProjectRequest = useJiraProjectCreationRequest();

  const fields = [
    {
      label: "Email",
      id: "email",
      validation: {required: "Required field"}
    },
    {
      label: "Project Name",
      id: "projectName",
      validation: {required: "Required field"}

    },
    {
      label: "Project Key",
      id: "projectKey",
      validation: {required: "Required field"}
    },
    {
      label: "Project Lead Email",
      id: "projectLeadEmail",
      validation: {required: "Required field"}
    },
    {
      label: "Project Type",
      id: "projectType",
      validation: {required: "Required field"}
    },

    {
      label: "Board Type",
      id: "boardType",
      validation: {required: "Required field"}
    },

  ];

  const projectTypes = [
    {label:'Kanban Software Development', value: 'KANBAN'},
    {label:'Scrum Software Development', value: 'SCRUM'}, 
    {label: "Basic Software Development", value: 'SOFTWARE_DEVELOPMENT'},
    {label: "Xray Test Project", value: "XRAY"}
  ];
  const boardTypes = [{label:'Kanban Board', value: 'KANBAN'},{label:'Scrum Board', value: 'SCRUM'}];


  const handleSelectChange = (event, id) => {
    setValue(id, event.target.value, { shouldValidate: true });
  };
  
  return (
    <Stack sx={{paddingTop: {xs:10, xl: 43}, alignItems: "flex-start", }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 5,
          width: "100%",
        }}
      >
        <img src={jiralogo} style={{ height: 40 }} alt="jira logo" />
        <Typography variant="h5">Request New Jira Project</Typography>
      </Stack>
      <Grid2
        container
        columns={12}
        sx={{
          marginTop: 20,
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
          rowSpacing:20
        }}
        component="form"
        onSubmit={handleSubmit(jiraProjectRequest.mutate)}
      >
        <Grid2
          container
          item
          size={12}
          maxWidth={{md:"600px"}}
          columnSpacing={{md: 60}}
          rowSpacing={{xs: 1, md:10}}
          direction={{xs:'column', md:'row'}}
          alignItems={{xs: 'center', md: ""}}
        >
          {fields.map((field) => {

              if(field.id === "projectType"){
                return (
                  <Grid2 item size={{md: 6}}>
                    <CustomSelect 
                      label={field.label} 
                      id={field.id} 
                      options={projectTypes}
                      handleSelectChange={handleSelectChange}
                      {...registerField(field.id, {...field.validation})}
                    />
                  </Grid2>
                )

              } else if(field.id === "boardType"){
                if(projectType === "SOFTWARE_DEVELOPMENT"){
                  return (
                  <Grid2 item size={{md: 6}}>
                    <CustomSelect 
                      label={field.label} 
                      id={field.id} 
                      options={boardTypes}
                      handleSelectChange={handleSelectChange}
                      {...registerField(field.id, {...field.validation})}
                    />
                  </Grid2>
                  )
                }
              } else {
                return(
                  <Grid2 item size={{md: 6}}>
                  <CustomTextField
                    labelText={field.label}
                    {...registerField(field.id, {...field.validation})}
                  />
                </Grid2>
                );
              }
          })}
        </Grid2>
        <Grid2 item sx={{marginTop: 20}}>
          <LoadingButton
            variant="contained"
            sx={{ marginLeft: "auto", marginRight: "auto", width: "95px", height:'37px' }}
            type="submit"
            isLoading={jiraProjectRequest.isPending}
          >
            Submit
          </LoadingButton>
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export default NewJiraProject;
