import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useState } from "react";
import CustomDataGrid from "../../../../../components/CustomDataGrid";
import DragandDropZone from "../../../../../components/DragandDropZone";
import { FILE_TYPES } from '../../../../../config/constants/selfServe';
import { setAlert } from "../../../../../store/appStore";
import store from "../../../../../store/store";
import { ERROR_MESSAGES } from "../../../../../utils/errorCode";
import { isVaildMail } from '../../../../../utils/helper';
import { csvParser, getUserDetailsFromMail } from "../../../../../utils/helper";


const AddUsers = (props) => {
  const { userData: rows, setUserData: setRows, setError } = props;
  const [isCSVProcessing, setIsCSVProcessing] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openFileInput, setOpenFileInput] = useState(false);
  const columns = [
    {
      field: "id",
      headerName: "No.",
      type: "integer",
      // editable: true,
      width: 50,
      align: "center",
    },
    {
      field: "email",
      headerName: "Email Id",
      type: "string",
      editable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.errorMessage} placement="right">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    },
    { field: "userName", headerName: "Username", type: "string", flex: 1 },
    { field: "firstName", headerName: "First Name", type: "string", flex: 1 },
    { field: "lastName", headerName: "Last Name", type: "string", flex: 1 },
    {
      filed: "delete",
      headerName: "",
      type: "actions",
      width: 50,
      getActions: (row) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleRowDelete(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowDelete = (id) => {
    setTableRows(
      rows
        .filter((item) => item.id !== id)
        .map((item, idx) => {
          item.id = idx + 1;
          return item;
        })
    );
    store.dispatch(setAlert({
      message: "User record deleted",
      alertLevel: "success"
    }))    
  };

  const handleRowAdd = () => {
    console.log(rows);
    const newRow = {
      id: rows[rows.length - 1]?.id + 1 > 1 ? rows[rows.length - 1]?.id + 1 : 1,
      email: "",
      firstName: "",
      lastName: "",
      userName: "",
    };
    setTableRows([...rows, newRow], false);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: "edit", fieldToFocus: "email" },
    }));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    const { firstName, lastName, userName } = getUserDetailsFromMail(
      updatedRow.email
    );
    updatedRow.firstName = firstName;
    updatedRow.lastName = lastName;
    updatedRow.userName = userName;
    setTableRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const setTableRows = (rows, setErrorStatus = true) => {
    let hasError = false;
    if(setErrorStatus){
      rows.forEach((row) => {
        const result = isVaildMail(row.email);
        if (!result.isValid) {
          row.error = true;
          row.errorMessage = ERROR_MESSAGES[result.code];
          if (!hasError) hasError = true;
        } else if (row.error) {
          delete row.error;
          delete row.errorMessage;
        }
      });
      setError(hasError);
    }
    setRows(rows);
  };

  const processCSV = (file) => {
    setIsCSVProcessing(true);
    csvParser(file)
        .then(csvData => {
            console.log(csvData)
            let rowData = [];
            let dataCount = rows[rows.length - 1]?.id + 1 > 1 ? rows[rows.length - 1]?.id + 1 : 1;
            csvData.forEach(row => {
                const mailIds = row.split(",");
                mailIds.forEach(mailId => {
                    const {firstName, lastName, userName} = getUserDetailsFromMail(mailId);
                    rowData.push(
                        {   id: dataCount,
                            email: mailId,
                            firstName: firstName, 
                            lastName: lastName, 
                            userName: userName
                        }
                    );
                    dataCount = dataCount + 1;
                })
            });
            setTableRows([...rows, ...rowData]);
            store.dispatch(setAlert({
                message: "CSV Loaded Successfully",
                alertLevel: "success"
              }))    

        })
        .catch(error => {
            console.log(error)
            store.dispatch(setAlert({
                message: "CSV Failed to Load",
                alertLevel: "error"
              }))   
        })
        .finally(() => {
            setIsCSVProcessing(false)
        })
}  

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "95%",
          alignSelf: "center",
          columnGap: 7,
        }}
      >
        <Button startIcon={<AddIcon />} onClick={() => setOpenFileInput(true)}>
          Add CSV
        </Button>
        <Button startIcon={<AddIcon />} onClick={handleRowAdd}>
          Add User
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          width: "95%",
          alignSelf: "center",
          marginTop: 5,
          height: { xs: 410, sm: 400, md: 400, lg: 370, xl: 501 },
        }}
      >
        <CustomDataGrid
          rows={rows}
          columns={columns}
          loading={isCSVProcessing}
          disableRowSelectionOnClick
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) => (params.row.error ? "DataError" : "")}
          sx={(theme) => ({
            border: "none",
            backgroundColor:
              theme.palette.mode === "light" ? "#e8e8e8" : "#191f25",
            "& .MuiDataGrid-cell": { border: "none" },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              backgroundColor: "red",
            },
            "& .MuiDataGrid-row": { borderBottom: "none" },
            // "& .MuiDataGrid-row:hover": {background: 'transparent !important'},
            "& .MuiDataGrid-filler": { display: "none" },
            "& .MuiDataGrid-columnSeparator": { display: "none" },
            "& .DataError": { backgroundColor: "#f44336"},
            // "& .DataError:hover": { backgroundColor: "#f44336 !important"},
            "& .MuiDataGrid-cell:focus": {outline: 'none'}
          })}
        />
      </Box>
      <DragandDropZone
            open={openFileInput} 
            onClose={() => setOpenFileInput(false)}
            accept={[FILE_TYPES.CSV]}
            getFiles={(files) => processCSV(files[0])} 
      />
    </Stack>
  );
};

export default React.memo(AddUsers);
