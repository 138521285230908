import { BT_ALLOWED_ADDRESSES } from "../config/constants/selfServe";
import { ERROR_MESSAGES, ERRORS } from "./errorCode";

export const emailValidation = {
    required: "Email is required",
    validate: {
      characters: (value) =>
       /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+){1,2}@[^\s@]+\.[^\s@]+$/i?.test(value) ||
        ERROR_MESSAGES[ERRORS.INVALID_MAIL],
      domain: (value) =>
        BT_ALLOWED_ADDRESSES.some((domain) =>
          value.endsWith(domain)
        ) || ERROR_MESSAGES[ERRORS.INVALID_DOMAIN],
    },
  }