import { createBrowserRouter } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import LandingPage from "../pages/LandingPage";
import Dashboard from "../pages/Dashboard";
import { PATHS } from "../config/constants/selfServe";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppContainer />,
        children:[
            {
                path: PATHS.LANDING_PAGE,
                element: <LandingPage />,
            },{
                path: PATHS.DASHBOARD,
                element: <Dashboard />
            }
        ]
    }
]);


export default router;