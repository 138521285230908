import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useColorScheme,
} from "@mui/material";
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';
import { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from "react-router-dom";
import logo from "../assets/btlogo.png";
import ErrorBoundary from "./ErrorBoundary";
import Footer from "./Footer";
import { PATHS } from "../config/constants/selfServe";
import { clearAlert, clearUser, setLoadingUserData, setUser } from "../store/appStore";
import CustomAlert from "./CustomAlert";



/*
 * Component for structural layout for application including
 * AppBar, Footer, Main content area and Light/Dark mode switch.
 * Ensures a fluid layout where the main content dynamically adjusts
 * between the header and footer.
 */


const AppContainer = (props) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { mode, setMode } = useColorScheme();
  const dispatchAction = useDispatch();
  const alertMessage = useSelector((state) => state.app.alertMessage);
  const alertLevel = useSelector((state) => state.app.alertLevel);
  const location = useLocation();
  const user = useSelector((state) => state.app.user);
  const appBarRef = useRef();

  const hasError = () => {
    const value = alertMessage !== null && alertMessage && alertMessage !=="";
    return value;
  };

  const logOut = async () => {
    if(user?.isLoggedIn){
      let response = await signOut();
      dispatchAction(clearUser())
    }
  }

  useLayoutEffect(() => {
    const fetchAuthenticatedUser = async () => {
      dispatchAction(setLoadingUserData({isLoadingUserData: true}));
      try{
        const userData = await fetchUserAttributes();
        dispatchAction(setUser({data: userData}));
        dispatchAction(setLoadingUserData({isLoadingUserData: false}));
      } catch (e){
        console.log(e)
      } finally{
        dispatchAction(setLoadingUserData({isLoadingUserData: false}));
      }
    }
    fetchAuthenticatedUser();
  },[]);

  return (
    <ErrorBoundary>
      <Stack sx={{ minHeight: "100vh" }}>
        <AppBar
          position="sticky"
          enableColorOnDark
          sx={{ backgroundImage: "none" }}
          elevation={0}
        >
          <Toolbar ref={appBarRef}>
            <Box sx={{ display: "flex", alignItems: "center", padding: 5 }} >
              <img src={logo} alt="bt-logo" style={{ height: "50px" }} />
              <Typography
                sx={{
                  fontSize: "1.063rem",
                  fontWeight: 700,
                  maxWidth: 50,
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                TOOLS PORTAL
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                onClick={() => {
                  mode === "dark" ? setMode("light") : setMode("dark");
                }}
                sx={{color:'#ffffff'}}
              >
                {mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
              {
                location.pathname === PATHS.DASHBOARD
                ? <IconButton sx={{color:'#ffffff'}} title="Log out" onClick={logOut}> <LogoutIcon /> </IconButton>
                : <></>
              }

            </Box>
          </Toolbar>
        </AppBar>
        {/* placeholder to display components from child routes */}
        <Stack direction="column" sx={{minHeight:'77vh', flexGrow: 1}}>
        <Outlet />
        </Stack>
        <Footer links={[
          { text: "Networks Jira", url: "https://jira.tvac.bt.co.uk" },
          { text: "Networks Wiki", url: "https://wiki.tvac.bt.co.uk" },
          { text: "Networks Gitlab", url: "https://code.tvac.bt.co.uk" },
          { text: "Cloud Portal", url: "https://cloud-portal.core.btcsp.co.uk" },
          ]}
        />
      </Stack>

      <CustomAlert 
          severity={alertLevel} 
          open={hasError()} 
          message={alertMessage} 
          onClose={() => {dispatchAction(clearAlert())}} 
          autoHideDuration={5000} 
          anchorEl={appBarRef.current}
        />
    </ErrorBoundary>
  );
};

export default AppContainer;
