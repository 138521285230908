import { BT_ALLOWED_ADDRESSES } from "../config/constants/selfServe";
import { ERRORS, SUCCESS } from "./errorCode";
import { useForm } from "react-hook-form";
import { DATA_KEYS, HTTPS_STATUS_CODE_KEYS, MESSAGE_KEYS } from "../config/constants/selfServe";



export function isVaildMail(email) {
  const emailRegex = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+){1,2}@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(email))
    return { isValid: false, code: ERRORS.INVALID_MAIL };
  
  if (!BT_ALLOWED_ADDRESSES.some((domain) => email.endsWith(domain))) 
    return { isValid: false, code: ERRORS.INVALID_DOMAIN };
  
  return { isValid: true, code: SUCCESS.VALID_MAIL };
};

export function debounce(func, delay){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

export function toSentenceCase(text) {
  return text
    .split(/([.!?]\s*)/)
    .map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase())
    .join("");
}


export const useHookForm = () => {
    const { register, handleSubmit, formState, setValue, watch} = useForm();
    const registerField = (name, options) => {
        return {
            ...register(name, {...options}),
            error: !!formState.errors[name],
            helperText: formState.errors[name] ? formState.errors[name].message : ""
        }
    };

    return {registerField, handleSubmit, formState, setValue, watch};
};


export const transformSelfServeSuccessResponse = (response) => {

    //check if any of the keys in list are present in response
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in response.data);
    const dataKey = DATA_KEYS.find(key => key in response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in response.data);

    // transform the custom keys to standard http reponse keys
    if(statusCodeKey){
        response.data.status = response.data[statusCodeKey];
        delete response.data[statusCodeKey];
    }

    if(dataKey){
        response.data.data = response.data[dataKey];
        delete response.data[dataKey];
    }

    if(messageKey){
        if(Array.isArray(response.data[messageKey]))
            response.data.message = response.data[messageKey].join("-");
        else
            response.data.message = response.data[messageKey]
    
        delete response.data[messageKey];
    }

    return response;

};

export const transformSelfServeFailureResponse = (error) => {
    
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in error.response.data);
    const dataKey = DATA_KEYS.find(key => key in error.response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in error.response.data);
    if(statusCodeKey){
        error.response.data.status = error.response.data[statusCodeKey];
        delete error.response.data[statusCodeKey];
    }

    if(dataKey){
        error.response.data.data = error.response.data[dataKey];
        delete error.response.data[dataKey];
    }
        
    if(messageKey){
        if(Array.isArray(error.response.data[messageKey]))
            error.response.data.message = error.response.data[messageKey].join("-");
        else
            error.response.data.message = error.response.data[messageKey];

        delete error.response.data[messageKey];
    }

    return error;
};

export const logToCloudWatch = (logData) => {

}

export const getUserDetailsFromMail = (emailId) => {
    let userName = emailId.split("@")[0];
    let firstName = userName.split(".")[0];
    let lastName = userName.split(".").at(-1);
    
    // if (userName.split(".").length === 3) {
    //     lastName = userName.split(".")[1] + "." + userName.split(".")[2];
    // } else {
    //     lastName = userName.split(".")[1];
    // }

    return ({firstName: firstName, lastName: lastName, userName: userName});
}

export const csvParser = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const csvString = e.target.result;
            const csvRows = csvString.trim().split("\n").map(row => row.trim());
            resolve(csvRows);
        }

        fileReader.onerror = (error) => reject(error);
        fileReader.readAsText(file);
    })
}


