import { Box, CircularProgress } from "@mui/material";
import SelfServeOptions from "./SelfServeOptions";
import LogIn from "./LogIn"
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = (props) => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.app.user);
  const isLoadingUserData = useSelector((state) => state.app.isLoadingUserData);

  useEffect(() => {
    if(!isLoadingUserData && user.isLoggedIn){
      navigate('/dashboard');
    }
  },[isLoadingUserData, user]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        paddingBottom: 15,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: { xs: "center", sm: "flex-start" },
          flexGrow: { xs: 1, sm: 0 },
          columnGap: { sm: 40, md: 100 },
          rowGap: { xs: 20 },
        }}
      >
        {isLoadingUserData ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <>
            <SelfServeOptions />
            <LogIn />
          </>
        )}
      </Box>
    </Box>
  );
};

export default LandingPage;
