import { Box } from "@mui/material";

const Drawer = (props) => {
  return (
    <Box
      sx={{
        flexDirection: "column",
        height: "100%",
        backgroundColor: "footer.main",
        width: {xs: '50px', sm: '50px', md:"250px"},
        display: "flex",
        paddingTop: 10,
        flexShrink: 0,
      }}
    >
      {props.children}
    </Box>
  );
};

export default Drawer;
