import { Stack, TextField, Typography } from "@mui/material";
import { forwardRef } from "react";

const CustomTextField = (props, ref) => {
    return(
        <Stack spacing={3}>
            <Typography>{props?.labelText}</Typography>
            <TextField {...props} ref={ref}/>
        </Stack>
    );
};

export default forwardRef(CustomTextField);