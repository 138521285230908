import { ExpandMore } from "@mui/icons-material";
import { ExpandLess } from "@mui/icons-material";
import { Badge, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useState } from "react";

const CustomStyledList = styled(List)({
    '& .MuiListItem-root':{
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    '& .MuiListItemButton-root': {
        paddingLeft: 20,
        paddingRight: 20,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,

      },
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        fontWeight:400

      },
});

const CustomList = (props) => {

  const [selected, setSelected] = useState(props.value);
  const [selectedSubOption, setSelectedSubOption] = useState();

  return (
    <CustomStyledList sx={props.sx}>
      {props.listNavItems?.map((listNavItem, index) => {
        return (
          <>
            <ListItem>
              <ListItemButton onClick={() => {
                  if(index !== selected && listNavItem.nestedOptions?.length > 0){
                    setSelectedSubOption();
                  }
                  setSelected(index); 
                  listNavItem?.onClick?.()
                }
              }>

                <ListItemIcon
                  {...((selected === index && !listNavItem.nestedOptions?.length) && {
                    sx: { color: "primary.main" },
                  })}
                >
                  <Badge badgeContent={listNavItem.badgeContent} color="primary">
                    {listNavItem?.icon}
                  </Badge>
                </ListItemIcon>
                  <ListItemText
                    primary={listNavItem?.text}
                    sx={{
                      visibility: { xs: "hidden", md: "visible" },
                      ...((selected === index && !listNavItem.nestedOptions?.length) && {
                        color: "primary.main",
                      }),
                    }}
                  />
                {(listNavItem.nestedOptions?.length > 0) 
                  ? (selected === index) ? <ExpandLess sx={{visibility: { xs: "hidden", md: "visible" }}}/> : <ExpandMore sx={{visibility: { xs: "hidden", md: "visible" }}}/>
                  : <></>
                }
              </ListItemButton>
            </ListItem>

            {/* nested list items*/}
            {listNavItem.nestedOptions?.length > 0 ? (
              listNavItem.nestedOptions?.map((nestedNavListItem, idx) => {
                return (
                  <Collapse
                    in={selected === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding >
                      <ListItemButton sx={{ paddingLeft: 10 }} onClick={() => {setSelectedSubOption(idx); nestedNavListItem?.onClick?.()}}>
                        <ListItemText 
                          primary={nestedNavListItem.text} 
                          sx={{
                            visibility: { xs: "hidden", md: "visible" },
                            ...(selectedSubOption === idx && {
                              color: "primary.main",
                            }),
                          }}
                        
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                );
              })
            ) : (
              <></>
            )}
          </>
        );
      })}
      {props?.children}
    </CustomStyledList>
  );
}


export default CustomList;